const projects = [
  {
    title: "Winnipeg Transit",
    image: "./images/wt-mockup.png",
    description:
      "Transit Schedule WebApp built with Nextjs, React, OneSignal and Winnipeg Transit's API. Features include setting alerts for bus arrival and save important stops. .",
    tools: ["Nextjs", "React", "Material UI"],
    github: "https://github.com/jobanpreet27/winnipeg-transit-webapp-nextjs",
    link: "https://wtransit.herokuapp.com/",
  },
  {
    title: "Mobile Browser",
    image: "./images/mb-mockup.png",
    description:
      "Movie broswer built using React-Native, Expo and OMDb API. It allows user to search movies and is supported on both android and iOS. ",
    tools: ["React-Native", "Redux", "Expo"],
    github: "https://github.com/jobanpreet27/movie-browser",
    link: "https://github.com/jobanpreet27/movie-browser",
  },
  {
    title: "Python Notes App",
    image: "./images/pn-mockup.png",
    description:
      "Python Notes App built using Python, Flask and Bootstrap. Users can register using email and securely save their notes.",
    tools: ["Python", "Flask", "Bootstrap"],
    github: "https://github.com/jobanpreet27/python-notes-app",
    link: "https://python-notesapp.herokuapp.com",
  },
];

export default projects;
